import { BitmovinAdapterWithIma } from './adapter/bitmovin';
import { MediaType } from './stream/enums';
import { pickHighestRate } from './stream/utils';
import { Player } from './player/player';
import { AdapterCallbacks } from './adapter/adapter-callbacks';
import { AdapterCallbacksDispatcher } from './adapter/adapter-callbacks-dispatcher';
import { EventKey, PlaybackState, } from './player/enums';
import { WebPlayer } from './player/web-player';
import { ChannelLogger, ConsoleLogger, } from './logger';
import { StreamProtocol, StreamProtection, SubtitlesCodec, } from './capability';
import { Client } from './clients/clients';
import { isApple, isChrome, } from './clients/device-detection';
const detectCapabilities = () => {
    if (isApple) {
        return {
            default: {
                streamProtocol: StreamProtocol.HLS,
                streamProtection: StreamProtection.FAIRPLAY,
            },
        };
    }
    else if (isChrome) {
        return {
            default: {
                streamProtocol: StreamProtocol.DASH,
                streamProtection: StreamProtection.WIDEVINE,
            },
            optional: [
                {
                    streamProtocol: StreamProtocol.HLS,
                    streamProtection: StreamProtection.NONE,
                },
            ],
        };
    }
    else {
        return {
            default: {
                streamProtocol: StreamProtocol.DASH,
                streamProtection: StreamProtection.WIDEVINE,
            },
        };
    }
};
const createPlayer = (options) => {
    const capabilities = detectCapabilities();
    return new WebPlayer(options, new BitmovinAdapterWithIma(options, capabilities), capabilities);
};
export { AdapterCallbacks, AdapterCallbacksDispatcher, ChannelLogger, Client, ConsoleLogger, EventKey, MediaType, PlaybackState, Player, StreamProtection, StreamProtocol, SubtitlesCodec, WebPlayer, createPlayer, pickHighestRate, };
