import { BufferType as BitmovinBufferType, HttpRequestType as BitmovinHttpRequestType, HttpRequestType, MediaType as BitmovinMediaType, Player as BitmovinPlayer, PlayerEvent as BitmovinPlayerEvent, TimeMode, TimelineReferencePoint, } from 'bitmovin-player/modules/bitmovinplayer-core';
import Abr from 'bitmovin-player/modules/bitmovinplayer-abr';
import ContainerMP4 from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import EngineBitmovin from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import HLS from 'bitmovin-player/modules/bitmovinplayer-hls';
import XML from 'bitmovin-player/modules/bitmovinplayer-xml';
import Dash from 'bitmovin-player/modules/bitmovinplayer-dash';
import DRM from 'bitmovin-player/modules/bitmovinplayer-drm';
import MseRenderer from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import Subtitles from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesTTML from 'bitmovin-player/modules/bitmovinplayer-subtitles-ttml';
import SubtitlesWebVTT from 'bitmovin-player/modules/bitmovinplayer-subtitles-vtt';
import Thumbnail from 'bitmovin-player/modules/bitmovinplayer-thumbnail';
import EngineNative from 'bitmovin-player/modules/bitmovinplayer-engine-native';
import SubtitlesNative from 'bitmovin-player/modules/bitmovinplayer-subtitles-native';
import ContainerTS from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import { StreamProtection, StreamProtocol, } from './../../capability';
import { isPlaybackError, PlaybackError, } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
import { calculateAbsoluteLiveEdge, calculateAbsoluteTimeshift, isBitmovinError, mapAudioTrack, mapBitmovinError, mapTextTrack, getAvailableRepresentations, debounce, } from './utils';
import { PlaybackState } from '../../player/enums';
import { AdapterCallbacksDispatcher } from '../adapter-callbacks-dispatcher';
import { MediaType } from '../../stream/enums';
import { CustomSubtitleDisplay, } from './subtitles-display';
import { createSourceConfiguration, } from './config';
import { WithIma } from '../ima/ima';
import { PLAYER_VIDEO_ID } from '../constants';
const EVENT_TIMEOUT = 50;
export const BITMOVIN_LICENSE_KEY = __INJ.BITMOVIN_LICENSE_KEY;
export class BitmovinAdapter extends AdapterCallbacksDispatcher {
    options;
    bitmovinPlayer;
    view;
    eventMap;
    sessionEventMap;
    pendingActivationSubtitlesTrack = null;
    subtitleDisplay = null;
    type = null;
    bandwidthMeasurements = [];
    get playerName() {
        return 'bitmovin';
    }
    get playerVersion() {
        return BitmovinPlayer.version;
    }
    getView() {
        return this.view;
    }
    constructor(options, capabilities) {
        super(options.logging?.adapterLogger);
        this.options = options;
        this.initPlayerModules(capabilities);
        this.onWindowOrPlayerResize = debounce(this.onWindowOrPlayerResize.bind(this), 500);
        this.eventMap = {
            [BitmovinPlayerEvent.Ready]: this.onBitmovinPlayerReady,
            [BitmovinPlayerEvent.DownloadFinished]: this.onBitmovinPlayerDownloadFinished,
            [BitmovinPlayerEvent.SourceLoaded]: this.onBitmovinSourceLoaded,
            [BitmovinPlayerEvent.SourceUnloaded]: this.onBitmovinSourceUnloaded,
            [BitmovinPlayerEvent.TimeChanged]: this.onBitmovinTimeChanged,
            [BitmovinPlayerEvent.Play]: this.onBitmovinPlay,
            [BitmovinPlayerEvent.Playing]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.Paused]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.StallStarted]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.StallEnded]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.Error]: this.onBitmovinPlayerError,
            [BitmovinPlayerEvent.AudioChanged]: this.onBitmovinSelectedAudioTrackChanged,
            [BitmovinPlayerEvent.SubtitleDisabled]: this.onBitmovinSelectedSubtitleTrackChanged,
            [BitmovinPlayerEvent.SubtitleEnabled]: this.onBitmovinSelectedSubtitleTrackChanged,
            [BitmovinPlayerEvent.CueEnter]: this.onCueEnter,
            [BitmovinPlayerEvent.CueExit]: this.onCueExit,
            [BitmovinPlayerEvent.Seek]: this.onSeek,
            [BitmovinPlayerEvent.Seeked]: this.onBitmovinSeeked,
            [BitmovinPlayerEvent.TimeShifted]: this.onBitmovinSeeked,
            [BitmovinPlayerEvent.VideoPlaybackQualityChanged]: this.onVideoQualityChanged,
            [BitmovinPlayerEvent.PlaybackFinished]: this.onBitmovinPlaybackFinished,
        };
        // we get more control over tracks changed events when we handle them manually
        // when a new session starts
        this.sessionEventMap = {
            [BitmovinPlayerEvent.AudioAdded]: this.onBitmovinAvailableAudioTracksChanged,
            [BitmovinPlayerEvent.AudioRemoved]: this.onBitmovinAvailableAudioTracksChanged,
            [BitmovinPlayerEvent.SubtitleAdded]: this.onBitmovinAvailableSubtitlesTracksChanged,
            [BitmovinPlayerEvent.SubtitleRemoved]: this.onBitmovinAvailableSubtitlesTracksChanged,
        };
    }
    resetState() {
        super.resetState();
        this.pendingActivationSubtitlesTrack = null;
        this.bandwidthMeasurements = [];
    }
    start(type, startupPosition, prePadding) {
        if (type === MediaType.RECORDING ||
            type === MediaType.REPLAY ||
            type === MediaType.VOD) {
            if (startupPosition === undefined) {
                return {
                    // Force both dynamic and static streams to start at the beginning
                    // with outside boundary value -1
                    startOffset: -1,
                    startOffsetTimelineReference: TimelineReferencePoint.Start,
                };
            }
            return {
                startOffset: (startupPosition ?? 0) + (prePadding ?? 0),
                startOffsetTimelineReference: TimelineReferencePoint.Start,
            };
        }
        if (startupPosition === undefined) {
            return undefined;
        }
        return {
            startOffset: startupPosition,
            startOffsetTimelineReference: TimelineReferencePoint.Start,
        };
    }
    async load(request) {
        this.resetState();
        this.type = request.type;
        this.psid = request.psid;
        const sourceConfig = createSourceConfiguration(request.capability, request.watchResponse.stream, request.playOptions, this.start(request.type, request.playOptions.startupPosition, request.watchResponse.stream.padding?.pre));
        await this.bitmovinPlayer?.load(sourceConfig);
        this.dispatchPlaybackStateChanged(PlaybackState.BUFFERING);
        this.dispatchAvailableAudioTracksChanged(this.getBitmovinAudioTracks());
        this.dispatchAvailableSubtitleTracksChanged(this.getBitmovinSubtitlesTracks());
        await this.setPreferredTracks(request.playOptions.preferredAudioLanguage, request.playOptions.preferredSubtitlesLanguage);
        await this.play();
        return Promise.resolve({
            url: request.watchResponse.stream.url,
            licenseUrl: request.watchResponse.stream.license_url ?? null,
        });
    }
    seek(position) {
        if (this.bitmovinPlayer?.isLive()) {
            const seekableRange = this.seekableRange();
            if (!seekableRange) {
                return;
            }
            this.bitmovinPlayer.timeShift(position - seekableRange.end);
        }
        else {
            this.bitmovinPlayer?.seek(position);
        }
    }
    setView(view) {
        this.view = view;
        const bitmovinPlayer = new BitmovinPlayer(view, {
            key: BITMOVIN_LICENSE_KEY,
            playback: {
                muted: this.options.muted,
            },
        });
        const videoNode = document.createElement('video');
        videoNode.setAttribute('id', PLAYER_VIDEO_ID);
        videoNode.setAttribute('playsinline', '');
        videoNode.setAttribute('webkit-playsinline', '');
        videoNode.style.width = '100%';
        videoNode.style.height = '100%';
        this.view.appendChild(videoNode);
        bitmovinPlayer.setVideoElement(videoNode);
        this.subtitleDisplay = CustomSubtitleDisplay(bitmovinPlayer?.getContainer());
        this.bitmovinPlayer = bitmovinPlayer;
        this.attachEvents();
    }
    setVolume(value) {
        this.bitmovinPlayer?.setVolume(value * 100);
    }
    getVolume() {
        return this.bitmovinPlayer?.getVolume() ?? 0;
    }
    // Override in KeplerAdapter
    initPlayerModules(capabilities) {
        BitmovinPlayer.addModule(EngineBitmovin);
        BitmovinPlayer.addModule(Abr);
        BitmovinPlayer.addModule(MseRenderer);
        BitmovinPlayer.addModule(Subtitles);
        BitmovinPlayer.addModule(SubtitlesTTML);
        BitmovinPlayer.addModule(SubtitlesWebVTT);
        BitmovinPlayer.addModule(Thumbnail);
        const allCapabilities = [
            capabilities.default,
            ...capabilities.optional ? capabilities.optional : [],
        ];
        const hlsRequired = allCapabilities.find((capability) => {
            return capability.streamProtocol === StreamProtocol.HLS;
        });
        const dashRequired = allCapabilities.find((capability) => {
            return capability.streamProtocol === StreamProtocol.DASH;
        });
        const drmRequired = allCapabilities.find((capability) => {
            return capability.streamProtection !== StreamProtection.NONE;
        });
        if (dashRequired) {
            BitmovinPlayer.addModule(XML);
            BitmovinPlayer.addModule(Dash);
        }
        if (hlsRequired) {
            BitmovinPlayer.addModule(HLS);
            BitmovinPlayer.addModule(EngineNative);
            BitmovinPlayer.addModule(SubtitlesNative);
        }
        if (drmRequired) {
            BitmovinPlayer.addModule(DRM);
        }
        if (dashRequired || hlsRequired) {
            BitmovinPlayer.addModule(ContainerMP4);
            BitmovinPlayer.addModule(ContainerTS);
        }
    }
    onVideoQualityChanged = (event) => {
        const bitrate = event.targetQuality?.bitrate;
        if (!bitrate) {
            return;
        }
        this.dispatchVideoBitrateChanged(bitrate);
    };
    onBitmovinPlaybackFinished = () => {
        this.dispatchPlaybackEnded();
        this.stop();
    };
    onCueEnter = (event) => {
        this.subtitleDisplay?.showCue(event);
    };
    onCueExit = (event) => {
        this.subtitleDisplay?.hideCue(event);
    };
    onBitmovinSeeked = () => {
        if (this.type === MediaType.REPLAY || this.type === MediaType.RECORDING) {
            this.dispatchSeeked(this.bitmovinPlayer?.getCurrentTime(TimeMode.RelativeTime) ?? 0);
        }
        else {
            this.dispatchSeeked(this.bitmovinPlayer?.getCurrentTime() ?? 0);
        }
    };
    onSeek = () => {
        this.subtitleDisplay?.clear();
    };
    /**
     * Dispatches the current position & seekable range based on the media type
     */
    dispatchMediaPositionAndSeekableRangeChange = (time) => {
        if (this.type === MediaType.REPLAY ||
            this.type === MediaType.RECORDING ||
            this.type === MediaType.VOD) {
            // Player could report NaN right after source loaded
            const relativeTime = this.bitmovinPlayer?.getCurrentTime(TimeMode.RelativeTime) || 0;
            // Player could report absolute time right after source loaded for dynamic streams
            this.dispatchPositionChange(relativeTime < 1e9 ? relativeTime : 0);
        }
        else {
            this.dispatchPositionChange(time ?? this.bitmovinPlayer?.getCurrentTime() ?? 0);
        }
        // seekableRange and positionChange are used togehter
        // to determine if seeking is allowed.
        // We need to keep the values as close as possible to
        // the real current values to prevent state fluctuation.
        // This couldn't wait for manifest download finished
        // as seekable range didn't include upcoming fragment range.
        this.dispatchSeekableRange(this.seekableRange());
    };
    onBitmovinTimeChanged = (event) => {
        this.dispatchMediaPositionAndSeekableRangeChange(event.time);
    };
    onBitmovinPlayerReady = () => {
        this.dispatchMediaPositionAndSeekableRangeChange();
        this.dispatchPlayerReady();
    };
    onBitmovinThroughputMeasurement = (event) => {
        if (event.downloadType !== BitmovinHttpRequestType.MEDIA_VIDEO ||
            !event.success ||
            event.size <= 10000) {
            return;
        }
        const BANDWIDTH_AVERAGE_LENGTH = 5;
        this.bandwidthMeasurements.push({
            size: (event.size * 8) / 1024,
            duration: event.downloadTime,
        });
        if (this.bandwidthMeasurements.length > BANDWIDTH_AVERAGE_LENGTH) {
            this.bandwidthMeasurements.shift();
        }
        const averageValues = this.bandwidthMeasurements.reduce((acc, val) => ({
            size: acc.size + val.size,
            duration: acc.duration + val.duration,
        }), {
            size: 0,
            duration: 0,
        });
        const average = averageValues.size / averageValues.duration;
        this.dispatchBandwidthMeasured(average);
    };
    onBitmovinPlayerDownloadFinished = (event) => {
        this.onBitmovinThroughputMeasurement(event);
        if (this.bitmovinPlayer?.isPlaying()) {
            return;
        }
        if (event.downloadType === HttpRequestType.MANIFEST_DASH ||
            event.downloadType === HttpRequestType.MANIFEST_HLS_MASTER ||
            event.downloadType === HttpRequestType.MANIFEST_HLS_VARIANT) {
            this.dispatchSeekableRange(this.seekableRange());
        }
    };
    onBitmovinSourceLoaded = () => {
        this.attachSessionEvents();
        this.dispatchPlaybackDurationChanged(this.bitmovinPlayer?.getDuration() ?? null);
        this.dispatchMediaPositionAndSeekableRangeChange();
    };
    onBitmovinSourceUnloaded = (event) => {
        this.onBitmovinPlaybackStateChanged(event);
        this.detachSessionEvents();
    };
    getBitmovinAudioTracks() {
        return this.bitmovinPlayer?.getAvailableAudio().map((track) => mapAudioTrack(track)) || [];
    }
    getBitmovinSubtitlesTracks() {
        return this.bitmovinPlayer?.subtitles?.list().map((track) => mapTextTrack(track)) || [];
    }
    onBitmovinPlay = () => {
        this.dispatchPlay();
    };
    onBitmovinPlaybackStateChanged = (event) => {
        this.dispatchPlaybackStateChanged(this.mapState(event));
    };
    onBitmovinAvailableAudioTracksChanged = () => {
        this.dispatchAvailableAudioTracksChanged(this.getBitmovinAudioTracks());
    };
    onBitmovinAvailableSubtitlesTracksChanged = () => {
        this.dispatchAvailableSubtitleTracksChanged(this.getBitmovinSubtitlesTracks());
    };
    onBitmovinSelectedSubtitleTrackChanged = (event) => {
        this.subtitleDisplay?.clear();
        switch (event.type) {
            case BitmovinPlayerEvent.SubtitleDisabled: {
                if (this.pendingActivationSubtitlesTrack === null) {
                    this.dispatchSelectedSubtitlesTrackChanged(null);
                }
                break;
            }
            case BitmovinPlayerEvent.SubtitleEnabled: {
                const currentTrack = mapTextTrack(event.subtitle);
                this.pendingActivationSubtitlesTrack = null;
                this.dispatchSelectedSubtitlesTrackChanged(currentTrack);
                break;
            }
            default:
                break;
        }
    };
    onBitmovinSelectedAudioTrackChanged = (event) => {
        this.dispatchSelectedAudioTrackChanged(mapAudioTrack(event.targetAudio));
    };
    onBitmovinPlayerError = (error) => {
        this.handleError(error);
    };
    onWindowOrPlayerResize = () => {
        if (!this.view) {
            return;
        }
        this.dispatchGeometryChanged({
            playerWidth: this.view.clientWidth,
            playerHeight: this.view.clientHeight,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
        });
    };
    seekableRange() {
        if (!this.bitmovinPlayer) {
            return null;
        }
        if (this.bitmovinPlayer.isLive()) {
            if (this.type === MediaType.REPLAY || this.type === MediaType.RECORDING) {
                return {
                    start: 0,
                    end: Math.abs(this.bitmovinPlayer.getMaxTimeShift()),
                };
            }
            const currentTime = this.bitmovinPlayer.getCurrentTime();
            const timeshift = this.bitmovinPlayer.getTimeShift();
            return {
                start: calculateAbsoluteTimeshift(currentTime, timeshift, this.bitmovinPlayer.getMaxTimeShift()),
                end: calculateAbsoluteLiveEdge(currentTime, timeshift),
            };
        }
        else {
            return {
                start: 0,
                end: this.bitmovinPlayer.getDuration(),
            };
        }
    }
    async setPreferredTracks(preferredAudioLanguage, preferredSubtitlesLanguage) {
        let preferredAudioTrack = this.findPreferredLanguageTrack(this.audioTracks, preferredAudioLanguage);
        const preferredSubtitlesTrack = this.findPreferredLanguageTrack(this.subtitlesTracks, preferredSubtitlesLanguage);
        if (preferredAudioTrack == null) {
            const currentAudio = this.bitmovinPlayer?.getAudio();
            if (currentAudio) {
                preferredAudioTrack = mapAudioTrack(currentAudio);
            }
        }
        await Promise.all([
            preferredAudioTrack
                ? this.setAudioTrack(preferredAudioTrack)
                : Promise.resolve(),
            preferredSubtitlesTrack
                ? this.setSubtitlesTrack(preferredSubtitlesTrack)
                : Promise.resolve(),
        ]);
        return Promise.resolve();
    }
    findPreferredLanguageTrack(availableTracks, preferredLanguage) {
        if (!preferredLanguage) {
            return null;
        }
        const preferredTrack = availableTracks.find((track) => {
            return track.locale === preferredLanguage;
        });
        if (!preferredTrack) {
            return null;
        }
        return preferredTrack;
    }
    attachResizeEvent() {
        window.addEventListener('resize', this.onWindowOrPlayerResize);
        this.bitmovinPlayer?.on(BitmovinPlayerEvent.PlayerResized, this.onWindowOrPlayerResize);
    }
    detachResizeEvent() {
        window.removeEventListener('resize', this.onWindowOrPlayerResize);
        this.bitmovinPlayer?.off(BitmovinPlayerEvent.PlayerResized, this.onWindowOrPlayerResize);
    }
    attachEvents() {
        Object.entries(this.eventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.on(eventKey, listener);
        });
        this.attachResizeEvent();
    }
    detachEvents() {
        Object.entries(this.eventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.off(eventKey, listener);
        });
        this.detachResizeEvent();
    }
    attachSessionEvents() {
        Object.entries(this.sessionEventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.on(eventKey, listener);
        });
    }
    detachSessionEvents() {
        Object.entries(this.sessionEventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.off(eventKey, listener);
        });
    }
    play() {
        const player = this.bitmovinPlayer;
        if (!player) {
            return Promise.resolve();
        }
        if (!player.getSource()) {
            this.handleError(new PlaybackError(ErrorCode.Source, 'No source loaded'));
            return Promise.resolve();
        }
        return player.play()
            .catch((error) => {
            // catch browser autoplay blocked when video is unmuted
            if (error.name === 'NotAllowedError') {
                this.handleError(error);
                return;
            }
            // https://developer.chrome.com/blog/play-request-was-interrupted
            if (error.name === 'AbortError') {
                this.handleError(error);
                return;
            }
            this.handleError(error);
        });
    }
    pause() {
        const player = this.bitmovinPlayer;
        if (!player) {
            return Promise.resolve();
        }
        player.pause();
        return Promise.resolve();
    }
    timeoutEvent = (event, error) => {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(error);
            }, EVENT_TIMEOUT);
            const resolveCallback = () => {
                clearTimeout(timeout);
                this.bitmovinPlayer?.off(event, resolveCallback);
                resolve();
            };
            this.bitmovinPlayer?.on(event, resolveCallback);
        });
    };
    setAudioTrack(track) {
        const bitmovinSelectedAudioTrack = this.bitmovinPlayer?.getAudio();
        if (bitmovinSelectedAudioTrack?.id === track.id && this.selectedAudioTrack === null) {
            this.dispatchSelectedAudioTrackChanged(track);
            return;
        }
        this.bitmovinPlayer?.setAudio(track.id);
        this.timeoutEvent(BitmovinPlayerEvent.AudioChanged, new PlaybackError(ErrorCode.AudioTrack, 'Error changing audio track')).catch((error) => this.handleError(error));
    }
    setSubtitlesTrack(track) {
        this.pendingActivationSubtitlesTrack = track;
        if (this.selectedSubtitlesTrack) {
            this.bitmovinPlayer?.subtitles.disable(this.selectedSubtitlesTrack.id);
            this.timeoutEvent(BitmovinPlayerEvent.SubtitleDisabled, new PlaybackError(ErrorCode.SubtitlesTrack, 'Error disabling subtitles track')).catch((error) => this.handleError(error));
        }
        if (!track) {
            return;
        }
        this.bitmovinPlayer?.subtitles.enable(track.id, true);
        this.timeoutEvent(BitmovinPlayerEvent.SubtitleEnabled, new PlaybackError(ErrorCode.SubtitlesTrack, 'Error changing subtitles track')).catch((error) => this.handleError(error));
    }
    getBufferInformation() {
        const bufferApi = this.bitmovinPlayer?.buffer;
        if (!bufferApi) {
            return null;
        }
        const audioForward = bufferApi.getLevel(BitmovinBufferType.ForwardDuration, BitmovinMediaType.Audio);
        const audioBackward = bufferApi.getLevel(BitmovinBufferType.BackwardDuration, BitmovinMediaType.Audio);
        const videoForward = bufferApi.getLevel(BitmovinBufferType.ForwardDuration, BitmovinMediaType.Video);
        const videoBackward = bufferApi.getLevel(BitmovinBufferType.BackwardDuration, BitmovinMediaType.Video);
        return {
            audioForward: audioForward.level,
            audioBackward: audioBackward.level,
            videoForward: videoForward.level,
            videoBackward: videoBackward.level,
            miniumVideoBufferForward: videoForward.targetLevel,
        };
    }
    getSystemStats() {
        return null;
    }
    async getCapabilities() {
        if (!this.bitmovinPlayer) {
            return null;
        }
        const supportedDrm = await this.bitmovinPlayer.getSupportedDRM();
        return {
            drm: supportedDrm.map((drm) => ({ name: drm })),
            // ToDo: https://zattoo2.atlassian.net/browse/PLY-1455
            audio: [],
            video: [],
            hdcp: [],
        };
    }
    async stop() {
        const hasSource = Boolean(this.bitmovinPlayer?.getSource());
        if (hasSource) {
            this.bitmovinPlayer?.unload()
                .catch((error) => {
                this.handleError(error);
            });
            await this.timeoutEvent(BitmovinPlayerEvent.SourceUnloaded, new PlaybackError(ErrorCode.Unload, 'Error unloading source')).catch((error) => this.handleError(error));
        }
        this.detachSessionEvents();
        return Promise.resolve();
    }
    async destroy() {
        await this.stop();
        this.detachEvents();
        this.bitmovinPlayer?.destroy()
            .catch((error) => {
            this.handleError(error);
        });
        this.view = undefined;
        this.bitmovinPlayer = undefined;
        return Promise.resolve();
    }
    handleError(error) {
        const mappedError = this.mapError(error);
        this.dispatchError(mappedError);
    }
    mapError(error) {
        if (isPlaybackError(error)) {
            return error;
        }
        if (isBitmovinError(error)) {
            return mapBitmovinError(error);
        }
        return new PlaybackError(ErrorCode.Unknown, String(error));
    }
    mapState(event) {
        const stateMap = {
            [BitmovinPlayerEvent.Playing]: PlaybackState.PLAYING,
            [BitmovinPlayerEvent.Paused]: PlaybackState.PAUSED,
            [BitmovinPlayerEvent.StallStarted]: PlaybackState.BUFFERING,
            [BitmovinPlayerEvent.StallEnded]: PlaybackState.PLAYING,
            [BitmovinPlayerEvent.SourceUnloaded]: PlaybackState.STOPPED,
        };
        return stateMap[event.type];
    }
    getAvailableRepresentations() {
        return Promise.resolve(getAvailableRepresentations(this.bitmovinPlayer?.getAvailableVideoQualities(), this.bitmovinPlayer?.getAvailableAudio(), this.bitmovinPlayer?.subtitles?.list()));
    }
}
const BitmovinAdapterWithIma = WithIma(BitmovinAdapter);
export { BitmovinPlayer, BitmovinAdapterWithIma, };
export { ModuleName, NetworkRequestApi, } from 'bitmovin-player';
