import { EventKey } from './enums/event-key';
import { isStatusEvent } from './utils';
const EVENTS_BATCH_INTERVALL_MS = 500;
const EVENTS_STATUS_INTERVALL_MS = 20 * 1000;
export class TelemetrySession {
    #createStatusProperties;
    #psid;
    #csid = null;
    #appId;
    #appVersion;
    #publicId;
    #endpoint = null;
    #telemetryDisabled = false;
    #queuedEvents = [];
    #sequence = 0;
    #batchTimeout = null;
    #statusTimeout = null;
    constructor(createStatusProperties, options) {
        this.#createStatusProperties = createStatusProperties;
        this.#psid = options.psid;
        this.#appId = options.appId;
        this.#appVersion = options.appVersion;
        this.#publicId = options.publicId;
        this.#restartStatusTimer();
    }
    #stopStatusTimer = () => {
        if (this.#statusTimeout) {
            clearTimeout(this.#statusTimeout);
            this.#statusTimeout = null;
        }
    };
    #restartStatusTimer = () => {
        this.#stopStatusTimer();
        this.#statusTimeout = setTimeout(() => {
            this.appendEvent({
                type: EventKey.STATUS,
                psid: this.#psid,
            });
            this.#restartStatusTimer();
        }, EVENTS_STATUS_INTERVALL_MS);
    };
    #restartSendEventsTimer = () => {
        if (this.#batchTimeout) {
            clearTimeout(this.#batchTimeout);
        }
        this.#batchTimeout = setTimeout(() => {
            this.#flush();
        }, EVENTS_BATCH_INTERVALL_MS);
    };
    #flush = async () => {
        if (!this.#endpoint) {
            return Promise.resolve();
        }
        if (this.#queuedEvents.length === 0) {
            return Promise.resolve();
        }
        const eventsPromise = Promise.all(this.#queuedEvents.slice());
        this.#queuedEvents = [];
        const events = await eventsPromise;
        const payload = {
            v: 2,
            events,
        };
        // todo: use beacon API if available
        await fetch(this.#endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        return Promise.resolve();
    };
    appendEventPromise = (partialEventPromise) => {
        if (this.#telemetryDisabled) {
            return;
        }
        const time = Date.now();
        const seq = this.#sequence;
        const eventPromise = partialEventPromise().then((partialEvent) => {
            const event = this.#addEventProperties({
                ...partialEvent,
                time,
                seq,
            });
            return event;
        });
        this.#queuedEvents.push(eventPromise);
        this.#sequence += 1;
        this.#restartSendEventsTimer();
        eventPromise.then(() => {
            this.#restartSendEventsTimer();
        });
    };
    #addEventProperties = (payload) => {
        const baseEvent = {
            psid: this.psid,
            csid: this.#csid ?? undefined,
            seq: this.#sequence,
            time: Date.now(),
            app_id: this.#appId,
            app_ver: this.#appVersion,
            public_id: this.#publicId,
        };
        if (isStatusEvent(payload)) {
            const statusProperties = this.#createStatusProperties();
            const event = {
                ...baseEvent,
                ...statusProperties,
                ...payload,
            };
            this.#restartStatusTimer();
            return event;
        }
        else {
            const event = {
                ...baseEvent,
                ...payload,
            };
            return event;
        }
    };
    appendEvent = (payload) => {
        if (this.#telemetryDisabled) {
            return;
        }
        this.#queuedEvents.push(this.#addEventProperties(payload));
        this.#sequence += 1;
        this.#restartSendEventsTimer();
    };
    setEndpoint(endpoint) {
        this.#endpoint = endpoint;
    }
    hasEndpoint() {
        return Boolean(this.#endpoint);
    }
    disableTelemetry() {
        this.#telemetryDisabled = true;
        this.#stopStatusTimer();
    }
    get psid() {
        return this.#psid;
    }
    setCsid(csid) {
        this.#csid = csid;
    }
    close() {
        this.#flush();
        this.#stopStatusTimer();
    }
}
